.projects-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  padding-top: 20px;
}

.services-box-container {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.service-box {
  width: 80%;
  height: 170px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.project-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

/* Tablet (Portrait) */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* Add your tablet-specific styles here */
  .projects-container {
    font-size: 1.5rem;
  }

  .services-box-container {
    width: 90%;
    height: 50%;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
  }

  .service-box {
    width: 80%;
    height: 180px;
  }
}

@media screen and (min-width: 1024px) {
  /* Add your tablet-specific styles here */
  .projects-container {
    font-size: 1.5rem;
    padding: 40px;
  }

  .services-box-container {
    width: 60%;
    height: 60%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    padding-top: 40px;
  }

  .service-box {
    width: 50%;
    height: 50%;
  }
}
