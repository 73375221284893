.home-container {
  width: 100vw;
  height: 100vh;
  margin-top: -60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: auto;
}

.profile-container {
  display: flex; /* Center the image inside */
  align-items: center; /* Center the image vertically */
  justify-content: center; /* Center the image horizontally */
  position: relative; /* Allow absolute positioning for text */
}

@keyframes flicker {
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    text-shadow: 0 0 4px #ffffff, 0 0 11px #ffffff, 0 0 19px #ffffff,
      0 0 40px rgba(224, 6, 224, 0.9), 0 0 80px rgba(224, 6, 224, 0.9),
      0 0 90px rgba(224, 6, 224, 0.9), 0 0 100px rgba(224, 6, 224, 0.9),
      0 0 150px rgba(224, 6, 224, 0.9); /* Keep the glow effect */
  }

  20%,
  24%,
  55% {
    text-shadow: none; /* Remove text shadow during flicker */
  }
}

.profile {
  width: 200px; /* Ensure it fills the container */
  height: 200px; /* Ensure it fills the container */
  border-radius: 50%; /* Match the container's rounded shape */
  background-color: rgba(248, 20, 248, 0.7); /* Use the neon pink background */
  box-shadow: 0 0 10px rgba(224, 6, 224, 0.7),
    /* Increased glow radius */ 0 0 20px rgba(224, 6, 224, 0.7),
    /* More pronounced glow */ 0 0 30px rgba(224, 6, 224, 0.7),
    /* Brightest glow */ 0 0 40px rgba(224, 6, 224, 0.7),
    /* Larger outer glow */ 0 0 50px rgba(224, 6, 224, 0.7); /* Maximum glow */
  object-fit: cover; /* Ensure the image fits within the circle */
}

.text-icon-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.neonText {
  color: #e006e0; /* Neon pink color */
  text-shadow: 0 0 4px rgba(224, 6, 224, 0.7), 0 0 10px rgba(224, 6, 224, 0.9),
    0 0 20px rgba(224, 6, 224, 1); /* Neon glow effect */
  animation: flicker 4s infinite alternate; /* Optional flicker animation */
}

.text {
  width: 95%;
  text-align: center;
}

.media {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.neon-icon {
  border: 2px solid #ff007a;
  border-radius: 50%;
  padding: 5px;
  transition: border-color 0.3s ease, color 0.3s ease; /* Include color in transition */
  color: #ff007a;
  cursor: pointer;
}

.neon-icon:hover {
  border-color: #e006e0;
  color: #e006e0;
}

.download-button {
  width: 150px;
  margin-top: 40px;
  border: 2px solid #ff007a; /* Change the color to your desired neon color */
  border-radius: 25px; /* Make the border circular */
  padding: 10px; /* Add some padding to the icons */
  transition: border-color 0.3s ease; /* Add a transition for a smooth effect */
  color: #ff007a;
  background: none;
  cursor: pointer;
}

.download-button:hover {
  border-color: #e006e0;
  color: #e006e0;
}

/* Tablet (Portrait) */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* Add your tablet-specific styles here */
  .home-container {
    flex-direction: row-reverse;
    margin-top: -200px;
  }

  .profile-container {
    flex: 1;
    margin-right: 20px;
  }

  .profile {
    width: 350px;
    height: 350px;
  }

  .text-icon-container {
    flex: 1;
    font-size: 20px;
    padding-left: 20px;
    margin-top: 0;
    align-items: flex-start;
  }

  .text {
    text-align: start;
  }

  .emily-clark {
    font-size: 50px;
  }

  .neon-icon {
    font-size: 30px;
    padding: 10px;
  }
}

@media screen and (min-width: 1024px) {
  /* Add your tablet-specific styles here */
  .home-container {
    flex-direction: row-reverse;
    margin-top: 0;
  }

  .profile-container {
    flex: 1;
  }

  .profile {
    width: 400px;
    height: 400px;
  }

  .text-icon-container {
    flex: 1;
    font-size: 20px;
    padding-left: 100px;
    margin-top: 0;
    align-items: flex-start;
  }

  .text {
    text-align: start;
  }

  .emily-clark {
    font-size: 50px;
  }

  .neon-icon {
    font-size: 30px;
    padding: 10px;
  }
}
