.sideNavContainer {
  width: 60%;
  height: 100vh;
  position: fixed;
  top: 60px; /* Make sure to specify units for the top position */
  right: 0;
  padding-left: 20px;
  background-color: rgba(0, 0, 0, 0.7); /* Black background with 70% opacity */
  color: #ffffff;
  border-top-left-radius: 20px;
  z-index: 100;
}

a {
  text-decoration: none;
}
