.outlet-container {
  width: 100%;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .section {
  height: 100vh; 
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
} */

/* Smooth scrolling for better UX */
html {
  scroll-behavior: smooth;
}
