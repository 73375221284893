.about-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: auto;
}

.about-text-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Tablet (Portrait) */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* Add your tablet-specific styles here */
  .about-container {
    flex-direction: row;
    align-items: center;
  }

  .about-profile-container {
    margin-left: 20px;
    margin-top: -300px;
  }

  .about-text-icon-container {
    margin-top: -300px;
    font-size: 20px;
    margin-left: 40px;
    align-items: flex-start;
  }
}

/* PC (Portrait) */
@media screen and (min-width: 1024px) {
  /* Add your tablet-specific styles here */
  .about-container {
    flex-direction: row;
  }

  .about-profile-container {
    flex: 1;
    padding-left: 100px;
  }

  .profile {
    width: 400px;
    height: 400px;
  }

  .about-text-icon-container {
    flex: 1;
    font-size: 20px;
    padding-left: 100px;
    margin-top: 0;
    align-items: flex-start;
  }
}
