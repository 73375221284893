.services-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  z-index: auto;
}

.box-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}

.box {
  width: 80%;
  height: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 2px solid #e006e0;
  text-align: center;
  padding: 8px;
  background-color: rgba(58, 58, 58, 0.7);
}

.service {
  font-size: 16px;
  font-weight: bold;
}

.service-description {
  font-size: 12px;
}

.service-icon {
  color: #ff007a;
  width: 40px;
  height: 60px;
}

.button {
  width: 100px;
  border: 2px solid #ff007a; /* Change the color to your desired neon color */
  border-radius: 25px; /* Make the border circular */
  padding: 3px; /* Add some padding to the icons */
  transition: border-color 0.3s ease; /* Add a transition for a smooth effect */
  color: #ff007a;
  background: none;
}

/* Tablet (Portrait) */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* Add your tablet-specific styles here */
  .services-container {
    font-size: 1.5rem;
    padding-top: 150px;
  }

  .box-container {
    width: 90%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
  }

  .box {
    width: 30%;
    height: 90%;
  }

  .service {
    font-size: 21px;
    font-weight: bold;
  }

  .service-description {
    font-size: 14px;
  }
}

/* PC (Portrait) */
@media screen and (min-width: 1024px) {
  .services-container {
    font-size: 1.5rem;
    padding-top: 150px;
  }

  .box-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
  }

  .box {
    width: 20%;
    height: 80%;
    padding: 20px;
  }

  .service {
    font-size: 20px;
    font-weight: bold;
  }

  .service-description {
    font-size: 16px;
  }
}
