.theme-container {
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(420deg);
  }
}

/* Apply the shake animation */
.shake {
  animation: rotate 0.5s ease-in-out;
}
