body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-x: hidden; /* Sometimes also required on the html element */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Light mode styles */
body.light {
  background-color: #ffffff;
  color: #000000;
  transition: color 0.3s ease, font-size 0.3s ease;
}

body.light .navbar {
  background-color: #ffffff;
}

/* Dark mode styles */
body.dark {
  background-color: #121212;
  color: #ffffff;
  transition: color 0.3s ease, font-size 0.3s ease;
}

.theme-icon {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit; /* Inherits the color based on the light/dark mode */
  transition: color 0.3s ease, font-size 0.3s ease; /* Smooth transitions */
}

a:hover {
  color: #df07f3; /* Highlight the anchor on hover */
}
