.menu-container {
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-dark {
  color: #000000;
}

.icon-light {
  color: #ffffff;
}

@media screen and (min-width: 1025px) {
  .menu-container {
    display: none;
  }
}
